import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from '../Screens/Login'
import Inicio from '../Screens/Inicio'
import Tecnicos from '../Screens/Tecnicos'
import Reportes from '../Screens/Reportes'
import Uno from '../Screens/Individual/CAJIGAS_ENCINAS_MIGUEL_ÁNGEL'
import Dos from '../Screens/Individual/ESQUER_ROBLES_JESÚS_ANTONIO'
//import Tres from '../Screens/Individual/IBARRA_FLORES_FERNANDO_ARTURO'
import Cuatro from '../Screens/Individual/MADRID_MORENO_ROSARIO'
import Cinco from '../Screens/Individual/MOLINA_LOUSTAUNAU_MANUEL'
import Seis from '../Screens/Individual/MOLINA_NAKAMURA_FRANCISCO_JAVIER'
import Siete from '../Screens/Individual/MURRIETA_MARTÍNEZ_RODRIGO'
import Ocho from '../Screens/Individual/NAVARRO_NICOLS_HERNAN'
import Nueve from '../Screens/Individual/RAMOS_JONHSON_AURELIO_ADALBERTO'
//import Diez from '../Screens/Individual/RASCÓN_ÁVILA_NOE_GUADALUPE'
import Once from '../Screens/Individual/RODRIGUEZ_ROMERO_ELEAZAR'
import Doce from '../Screens/Individual/TRASVIÑA_SOTO_JESÚS_ALFREDO'
import Trece from '../Screens/Individual/Trece'
import Catorce from '../Screens/Individual/Catorce'
import Quince from '../Screens/Individual/Quince'
import Dieciseis from '../Screens/Individual/Dieciseis'
import Diecisiete from '../Screens/Individual/Diecisiete'
import Dieciocho from '../Screens/Individual/Dieciocho'
import Diecinueve from '../Screens/Individual/Diecinueve'
import Veinte from '../Screens/Individual/Veinte'


const Routes = () => {
    return (
        <div>
            <Switch>
                <Route path="/" exact>
                    <Login />
                </Route>
                <Route exact path="/login" component={Login} />
                <Route exact path="/inicio" component={Inicio} />
                <Route exact path="/tecnicos" component={Tecnicos} />
                <Route exact path="/reportes" component={Reportes} />
                <Route exact path="/uno" component={Uno} />
                <Route exact path="/dos" component={Dos} />
                {/*<Route exact path="/tres" component={Tres} />*/}
                <Route exact path="/cuatro" component={Cuatro} />
                <Route exact path="/cinco" component={Cinco} />
                <Route exact path="/seis" component={Seis} />
                <Route exact path="/siete" component={Siete} />
                <Route exact path="/ocho" component={Ocho} />
                <Route exact path="/nueve" component={Nueve} />
                {/*<Route exact path="/diez" component={Diez} />*/}
                <Route exact path="/once" component={Once} />
                <Route exact path="/doce" component={Doce} />
                <Route exact path="/trece" component={Trece} />
                <Route exact path="/catorce" component={Catorce} />
                <Route exact path="/quince" component={Quince} />
                <Route exact path="/dieciseis" component={Dieciseis} />
                <Route exact path="/diecisiete" component={Diecisiete} />
                <Route exact path="/dieciocho" component={Dieciocho} />
                <Route exact path="/diecinueve" component={Diecinueve} />
                <Route exact path="/veinte" component={Veinte} />
            </Switch>
        </div>
    )
}

export default Routes
