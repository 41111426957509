import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
//import ReactDOM from "react-dom";
//import Typical from 'react-typical';
import "./styles.css";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#eeeeee",
        margin: "0px 5px",
        color: "#000000",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    }
}))

function Once() {
    // React States
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // User Login info
    const database = [
        {
            username: "erodriguez",
            password: "RRE11"
        }
    ];

    const errors = {
        uname: "Nombre de usuario no válido",
        pass: "Contraseña invalida"
    };

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        var { uname, pass } = document.forms[0];

        // Find user login info
        const userData = database.find((user) => user.username === uname.value);

        // Compare user info
        if (userData) {
            if (userData.password !== pass.value) {
                // Invalid password
                setErrorMessages({ name: "pass", message: errors.pass });
            } else {
                setIsSubmitted(true);
                document.getElementById("HomeTopBar").style.display = "block";
                document.getElementById("HomeTopBarFalsa").style.display = "none";
            }
        } else {
            // Username not found
            setErrorMessages({ name: "uname", message: errors.uname });
        }
    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    const theme = createTheme({
        typography: {
            fontFamily: [
                'Arial',
            ].join(','),
        },
    });

    // JSX code for login form
    const renderForm = (
        <div className="login">
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box className="limpiaPadding"
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "#000000" }}>
                            <LockIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Seguridad para Técnico Rodriguez Romero Eleazar
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                requiredonSubmit={handleSubmit}
                                fullWidth
                                id="uname"
                                label="Usuario"
                                name="uname"
                                autoComplete="uname"
                                autoFocus
                            />
                            {renderErrorMessage("uname")}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="pass"
                                label="Contraseña"
                                type="password"
                                id="pass"
                                autoComplete="pass"
                            />
                            {renderErrorMessage("pass")}
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Recordar información"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: "#000000" }}
                            >
                                Entrar
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    );

    // Inicio 
    const classes = useStyles();
    return (
        <div>
            {isSubmitted ?
                <div className={classes.otro}>
                    <div className="App">
                        <header className="App-header">

                            <h2 style={{ fontFamily: 'arial' }}>Captura de productos por Fase</h2>

                            <br />

                            <iframe title="Drive" src="https://drive.google.com/embeddedfolderview?id=17nGyyApo5KpH7g4WVkKXurCLiIssBLLa#grid" width="675" height="275"></iframe>

                            < br />

                            <p style={{ fontFamily: 'arial', fontSize: 20, textAlign: "justify" }}>
                                <i>● Fase 1: Plan de intervención y Programa de trabajo. <br />
                                    ● Fase 2: Evidencias de impartición de talleres de capacitación. <br />
                                    ● Fase 3: Informe Final del Proceso.</i>
                            </p>

                            <br />

                            <Button variant="contained" color="error" component={Link} to="/inicio"
                                sx={{ mb: 1, bgcolor: "#FF6060", width: 200, height: 35, color: "black", fontFamily: 'Arial', fontWeight: "bold" }}>CERRAR SESIÓN</Button>

                        </header>
                    </div>
                </div>
                : renderForm}
        </div>
    );
}

export default Once;