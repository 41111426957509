import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Container, Box } from '@material-ui/core';
import SAGARHPA from "../../assets/SAGARHPA.png";
import { createTheme } from '@mui/material/styles';

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#ffffff',
        marginTop: '0px',
        position: 'relative',
        left: '0',
        bottom: '0',
        right: '0'
    },
    footerText: {
        paddingRight: '4px',
        color: 'black',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    logo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }

}))

// eslint-disable-next-line
const theme = createTheme({
    typography: {
        fontFamily: [
            'Arial',
        ].join(','),
    },
  });

const Footer = () => {
    const classes = useStyles();
    return (
        <div>
            <Container style={{ bottom: 0, /*position:'absolute'*/position: 'end' }} className={classes.footer} maxWidth="max">
                <Box display="flex" style={{ paddingTop: '5px', paddingBottom: '5px' }}>

                    <Box justifyContent="flex-end" display="flex" flexGrow={1}>
                        <Box display="flex">
                            <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} textShadow={10} variant="h6" style={{ fontSize: "1.2vh" }} >2022</Typography></Box>
                        </Box>
                        <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} textShadow={10} variant="h6" style={{ fontSize: "1.2vh" }} >@</Typography></Box>
                        <Box display='flex' flexGrow={1}><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", fontFamily: 'arial' }} >Subsecretaria de Ganaderia de Sonora</Typography></Box>
                    </Box>

                    <Box id="HomeTopBarFalsa" justifyContent="center" display='flex' flexGrow={1}>
                        <Box
                            component="img"
                            sx={{
                                height: "10vh",
                                width: "10vh",
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                            }}
                            alt="SAGARHPA."
                            src={SAGARHPA}
                        />
                    </Box>

                    <Box justifyContent="flex-end" display="flex" flexGrow={1} visibility="hidden">
                        <Box display="flex">
                            <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} textShadow={10} variant="h6" style={{ fontSize: "1.2vh" }} >2022</Typography></Box>
                        </Box>
                        <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} textShadow={10} variant="h6" style={{ fontSize: "1.2vh" }} >@</Typography></Box>
                        <Box display='flex' flexGrow={1}><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", fontFamily: 'arial' }} >Subsecretaria de Ganaderia de Sonora</Typography></Box>
                    </Box>

                </Box>
            </Container>
        </div>
    )
}

export default Footer
