import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Box } from '@material-ui/core';
//import Drawer from './Drawer';
//import ReactJSsvg from '../../assets/ReactLogoSVG.svg';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import IconButton from '@mui/material/IconButton';
import Gobierno from "../../assets/Gobierno.png";
import Sonora from "../../assets/Sonora.png";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        paddingLeft: "0px",
        paddingRight: "",
    },
    TopBarText: {
        color: 'black',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    tabs: {
        flexGrow: 0,
        //marginLeft: theme.spacing(20)
    },
    title: {
        marginRight: '18%'
    },
    logo: {
        flexGrow: 0,
        marginLeft: theme.spacing(70),
    },
    paper: {
        //        padding: "0px",
        minWidth: "100px",
    },
    borde: {
        borderStyle: 'dashed',
        paddingBottom: '-10px',
        borderWidth: '0px 0px 2px 0px',
    }
}))

const TopBar = () => {
    const classes = useStyles();
    return (
        <div>
            <AppBar style={{ height: "6.5vh", paddingLeft: "0px" }} display='flex' position="fixed">
                <Toolbar className={classes.toolbar}>
                    <Box justifyContent="flex-start" style={{ paddingTop: "8px", paddingLeft: "25px", fontWeight: 'bold', fontSize: "2vh", color: "#000000" }} flexGrow={1}>
                        <Box
                            component="img"
                            sx={{
                                height: "6.3vh",
                                width: "15vh",
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                            }}
                            alt="Gobierno."
                            src={Gobierno}
                        />
                    </Box>

                    <Box id="HomeTopBarFalsa" justifyContent="center" display='flex' flexGrow={1}>
                        <IconButton size="large" disabled>
                            <HomeOutlinedIcon fontSize="large" />
                        </IconButton>
                    </Box>
                    <Box id='HomeTopBar' justifyContent="center" display='none' >
                        <IconButton aria-label="home" size='large' component={Link} to="/inicio">
                            <HomeOutlinedIcon fontSize="large" />
                        </IconButton>
                    </Box>

                    <Box justifyContent="flex-end" display="flex" flexGrow={1}>
                        <Box
                            component="img"
                            sx={{
                                height: "6.5vh",
                                width: "12vh",
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                            }}
                            alt="Sonora."
                            src={Sonora}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default TopBar
