import React from "react";
import { makeStyles } from '@material-ui/core/styles';
//import ReactDOM from "react-dom";
//import Typical from 'react-typical';
import "./styles.css";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  otro: {
    height: 'auto'
  },
  Dentro: {
    width: "100px", height: "100px",
    position: "relative", float: "left",
    backgroundColor: "#000000",
    margin: "0px 5px",
    color: "#ffffff",
    fontWeight: "bold"
  },
  column: {
    float: "left",
    width: "33.33%",
    padding: "5px"
  }
}))

// eslint-disable-next-line
const theme = createTheme({
  typography: {
    fontFamily: [
      'Arial',
    ].join(','),
  },
});

function Inicio() {
  // Inicio 
  const classes = useStyles();

  document.getElementById("HomeTopBar").style.display = "block";

  return (
    <div>
      <div className={classes.otro}>
        <div className="App">
          <header className="App-header">

            <h4 style={{ fontFamily: 'arial' }}>PROGRAMA DE DESARROLLO E IMPARTICIÓN DE CAPACITACIONES PARA LA PREVENCIÓN DE LA SEQUÍA.</h4>

            <p style={{ fontFamily: 'arial', fontSize: 25 }}>
              ACCEDER AL MENÚ QUE LE CORRESPONDA
            </p>

            <br />

            <Stack spacing={10} direction="row">
              <Button variant="contained" color="primary" component={Link} to="/tecnicos"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICOS</Button>
              <Button variant="contained" color="primary" component={Link} to="/reportes"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>REPORTES</Button>
            </Stack>
            <Stack spacing={15} direction="row">
              <Box style={{ fontFamily: 'arial', fontSize: 15 }}>
                <i>Acceso a Técnicos para captura de productos.</i>
              </Box>
              <Box style={{ fontFamily: 'arial', fontSize: 15 }}>
                <i>Acceso a personal autorizado para consulta de<br />
                  reportes de avance.</i>
              </Box>
            </Stack>

            <br /><br />
            <a href="https://drive.google.com/file/d/1I36xvUSmbifIvF9FWDC-Y4JWFqY-Rgs3/view?usp=sharing" target="_blank" style={{ fontFamily: 'arial', fontSize: 20 }}>Manual</a>

          </header>
        </div>
      </div>
    </div>
  );
}

export default Inicio;