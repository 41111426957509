import React from "react";
import { makeStyles } from '@material-ui/core/styles';
//import ReactDOM from "react-dom";
//import Typical from 'react-typical';
import "./styles.css";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  otro: {
    height: 'auto'
  },
  Dentro: {
    width: "100px", height: "100px",
    position: "relative", float: "left",
    backgroundColor: "#000000",
    margin: "0px 5px",
    color: "#ffffff",
    fontWeight: "bold"
  },
  column: {
    float: "left",
    width: "33.33%",
    padding: "5px"
  }
}))

// eslint-disable-next-line
const theme = createTheme({
  typography: {
    fontFamily: [
      'Arial',
    ].join(','),
  },
});

function Inicio() {
  // Inicio 
  const classes = useStyles();

  document.getElementById("HomeTopBar").style.display = "block";

  return (
    <div>
      <div className={classes.otro}>
        <div className="App">
          <header className="App-header">

            <h4 style={{ fontFamily: 'arial' }}>Técnicos</h4>

            <Stack spacing={2} direction="row">
              <Button variant="contained" color="primary" component={Link} to="/uno"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>CAJIGAS	ENCINAS	MIGUEL ÁNGEL</Button>
              <Button variant="contained" color="primary" component={Link} to="/dos"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>ESQUER 	ROBLES	JESÚS ANTONIO</Button>
            </Stack>
            <Stack spacing={2} direction="row">
              {/*<Button variant="contained" color="primary" component={Link} to="/tres"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>IBARRA	FLORES	FERNANDO ARTURO</Button>*/}
              <Button variant="contained" color="primary" component={Link} to="/cuatro"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>MADRID 	MORENO	ROSARIO</Button>
              <Button variant="contained" color="primary" component={Link} to="/seis"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>MOLINA	NAKAMURA	FRANCISCO JAVIER</Button>
              {/*<Button variant="contained" color="primary" component={Link} to="/cinco"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>MOLINA	LOUSTAUNAU	MANUEL</Button>*/}
            </Stack>
            <Stack spacing={2} direction="row">
              <Button variant="contained" color="primary" component={Link} to="/siete"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>MURRIETA	MARTÍNEZ	RODRIGO</Button>
              <Button variant="contained" color="primary" component={Link} to="/ocho"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>NAVARRO	NICOLS	HERNAN</Button>
            </Stack>

              {/*<Button variant="contained" color="primary" component={Link} to="/nueve"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>RAMOS	JONHSON	AURELIO ADALBERTO</Button>*/}

              {/*<Button variant="contained" color="primary" component={Link} to="/diez"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>RASCÓN	ÁVILA	NOE GUADALUPE</Button>*/}

            <Stack spacing={2} direction="row">
              <Button variant="contained" color="primary" component={Link} to="/once"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>RODRIGUEZ	ROMERO	ELEAZAR</Button>
              <Button variant="contained" color="primary" component={Link} to="/doce"
                sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TRASVIÑA	SOTO	JESÚS ALFREDO</Button>
            </Stack>
            <Box visibility={"hidden"} display={"none"}>
              <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" component={Link} to="/trece"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO TRECE</Button>
                <Button variant="contained" color="primary" component={Link} to="/catorce"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO CATORCE</Button>
              </Stack>
              <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" component={Link} to="/quince"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO QUINCE</Button>
                <Button variant="contained" color="primary" component={Link} to="/dieciseis"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO DIECISEIS</Button>
              </Stack>
              <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" component={Link} to="/diecisiete"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO DIECISIETE</Button>
                <Button variant="contained" color="primary" component={Link} to="/dieciocho"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO DIECIOCHO</Button>
              </Stack>
              <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" component={Link} to="/diecinueve"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO DIECINUEVE</Button>
                <Button variant="contained" color="primary" component={Link} to="/veinte"
                  sx={{ mb: 1, bgcolor: "#ffffff", width: 350, height: 35, color: "black", fontFamily: 'Arial' }}>TÉCNICO VEINTE</Button>
              </Stack>
            </Box>

            <br />

            <Box style={{ fontFamily: 'arial', fontSize: 15 }}>
              <i>Para la captura de productos, acceder usando su contraseña.</i>
            </Box>

          </header>
        </div>
      </div>
    </div>
  );
}

export default Inicio;